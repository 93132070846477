<template>
  <input class="standardInput" :type="type" v-model="inputVal" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number],
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style>
.standardInput {
  width: 100%;
  border-radius: 5px;
  resize: none;
  padding: 10px;
  border: 1px solid #c0c0c0;
  -webkit-appearance: none;
}
</style>