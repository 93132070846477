<template>
  <div class="prevRouter" @click="prevPage()">
    <ion-icon name="chevron-back-outline"></ion-icon><span>{{title}}</span>
  </div>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    prevPage() {
      this.$store.commit('CLEAR_MESSAGEBOX')
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.prevRouter {
  width: 200px;
  display: flex;
  height: 20px;
  cursor: pointer;
}
.prevRouter ion-icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>