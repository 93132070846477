<template>
  <div class="apiWrapper">
    <p>{{data.description}}</p>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VueJsonPretty
  },
  props: ["data"]
}
</script>

<style scoped lang="scss">
.apiWrapper {
  margin-top: 5px;
}


</style>