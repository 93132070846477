<template>
  <div class="pageWrapper">
    <Loader v-if="!product && !imagesIsLoaded" :color="'#444'" />
    <div v-if="product">
      <PrevPage
        title="Back to products"
      />

      <TopBar
        :baseProduct="baseProduct"
        :updateProduct="updateProduct"
        :isChanged="isChanged"
        :discardChanges="discardChanges"
        :saveChanges="saveChanges"
        :product="product"
        :title="product.productName == 'New product' ? 'Add product' : product.productName"
      />
      <MessageBox :data="{ transition: 0.2, height: '4vw', close: { auto: true, time: 5 } }" />
      <div class="divider">
        <div>
          <ProductInformation :product="product" />
          <br>
          <ProductImage
            @changeImageIsLoaded="changeImageIsLoaded"
            :product="product"
          />
          <br>
          <Pricing :product="product" />
          <br>
          <Preset
            :removePreset="removePreset"
            :addKiloPreset="addKiloPreset"
            :addUnitPreset="addUnitPreset"
            :presetKiloArraySorted="presetKiloArraySorted"
            :presetUnitArraySorted="presetUnitArraySorted"
            :presetUnitArray="presetUnitArray"
            :presetKiloArray="presetKiloArray"
          />
        </div>
        <div>
          <DisplayWebshop :product="product" :showInWebshop="showInWebshop" />
          <br>
          <Groups :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import ProductInformation from '@/components/products/viewProduct/ProductInformation'
import DisplayWebshop from '@/components/products/viewProduct/DisplayWebshop'
import ProductImage from '@/components/products/allProducts/ProductImage'
import Pricing from '@/components/products/viewProduct/Pricing'
import TopBar from '@/components/products/viewProduct/TopBar'
import Groups from '@/components/products/viewProduct/Groups'
import Preset from '@/components/products/viewProduct/Preset'
import MessageBox from '@/components/MessageBox'
import PrevPage from '@/components/PrevPage'
import Loader from '@/components/Loader'

export default {
  components: {
    Loader,
    TopBar,
    PrevPage,
    ProductInformation,
    DisplayWebshop,
    Groups,
    ProductImage,
    Preset,
    MessageBox,
    Pricing,
  },
  data() {
    return {
      product: null,
      baseProduct: null,
      imagesIsLoaded: false,

      presetValueKilo: undefined,
      presetKiloArray: [],
      basePresetKiloArray: [],
      sortedKilo: [],

      presetValueUnit: undefined,
      presetUnitArray: [],
      basePresetUnitArray: [],
      sortedUnit: [],
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChanged) {
      var answer = confirm("Do you really want to leave? You have unsaved changes!");
      if (answer) {
        return next()
      } else {
        return next(false)
      }
    }
    return next()
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    presetKiloArraySorted() {
      this.$forceUpdate()
      const tmp = [...this.presetKiloArray]
      return tmp.sort((a, b) => a - b)
    },
    presetUnitArraySorted() {
      this.$forceUpdate()
      const tmp = [...this.presetUnitArray]
      return tmp.sort((a, b) => a - b)
    },
    isChanged() {
      if (
        JSON.stringify(this.product) == JSON.stringify(this.baseProduct) &&
        JSON.stringify(this.presetKiloArray) ==
          JSON.stringify(this.basePresetKiloArray) &&
        JSON.stringify(this.presetUnitArray) ==
          JSON.stringify(this.basePresetUnitArray)
      ) {
        return false
      }
      return true
    },

    showInWebshop: {
      get() {
        if (this.product.webshop == 1) {
          return true
        }
        return false
      },
      set(newValue) {
        if (newValue) {
          this.product.webshop = 1
        } else {
          this.product.webshop = 0
        }
      },
    },
  },
  created() {
    this.getProduct()
  },
  methods: {
    removePreset(data, array) {
      const found = array.findIndex((element) => element == data)
      array.splice(found, 1)
      this.$forceUpdate()
    },
    sortArray(arr) {
      return arr.sort((a, b) => a - b)
    },
    addKiloPreset(presetVal) {
      if (!this.presetKiloArray.includes(Number(presetVal)) && presetVal) {
        this.presetKiloArray.push(Number(presetVal))
        this.$forceUpdate()
      }
    },
    addUnitPreset(presetVal) {
      if (!this.presetUnitArray.includes(Number(presetVal)) && presetVal) {
        this.presetUnitArray.push(Number(presetVal))
        this.$forceUpdate()
      }
    },
    deleteProduct() {
      var r = confirm(
        'Are you sure you want to delete ' + this.product.productName + '?'
      )
      if (r == true) {
        axios
          .post('products/deleteProduct', {
            productId: this.product.id,
          })
          .then((res) => {
            this.$router.push({ name: 'Products' })
          })
      }
    },
    changeImageIsLoaded() {
      this.imagesIsLoaded = true
    },
    getProduct() {
      axios
        .post('products/getProduct', {
          productId: this.$route.params.productId,
        })
        .then((res) => {
          this.kiloPrice = res.data.kiloPrice
          this.unitPrice = res.data.unitPrice
          this.presetUnitArray = JSON.parse(
            JSON.stringify(res.data.presetUnitArray)
          )
          this.basePresetUnitArray = JSON.parse(
            JSON.stringify(res.data.presetUnitArray)
          )
          this.presetKiloArray = JSON.parse(
            JSON.stringify(res.data.presetKiloArray)
          )
          this.basePresetKiloArray = JSON.parse(
            JSON.stringify(res.data.presetKiloArray)
          )
          this.product = JSON.parse(JSON.stringify(res.data))
          this.baseProduct = JSON.parse(JSON.stringify(res.data))
        })
        .catch((err) => {
          this.$router.push({ name: 'Products' })
        })
    },
    saveChanges() {
      if(this.product.description == undefined) {
        this.product.description = ''
      }
      if (this.product.showKiloWebshop) {
        this.product.showKiloWebshop = 1
      } else {
        this.product.showKiloWebshop = 0
      }

      if (this.product.showUnitWebshop) {
        this.product.showUnitWebshop = 1
      } else {
        this.product.showUnitWebshop = 0
      }

      axios
        .post('products/updateProduct', {
          newProductObj: this.product,
          productId: this.product.id,
          presetKiloArray: this.presetKiloArraySorted,
          presetUnitArray: this.presetUnitArraySorted,
          groups: this.product.groups,
          description: this.product.description,
        })
        .then((res) => {
          if(this.baseProduct.productName == 'New product') {
            this.$store.commit('SET_MESSAGEBOX', {message: 'You have successfully added a new product!', type: 'success'})
          } else {
            this.$store.commit('SET_MESSAGEBOX', {message: 'You have successfully updated ' + this.product.productName + '!', type: 'success'})
          }
          this.getProduct()
        })
        .catch((err) => {
          console.log(err)
          alert(err.response.data.msg)
          this.discardChanges()
        })
    },
    updateProduct(type) {
      if (type == 'delete' || type == 'forceDelete') {
        var r = true
        if (type != 'forceDelete') {
          r = confirm('Are you sure you want to delete this product?')
        }
        if (r == true) {
          axios
            .post('products/deleteProduct', {
              productId: this.$route.params.productId,
            })
            .then((res) => {
              this.$router.push({ name: 'Products' })
            })
        }
      } else {
        this.discardChanges()
      }
    },
    discardChanges() {
      this.presetKiloArray = JSON.parse(
        JSON.stringify(this.basePresetKiloArray)
      )
      this.presetUnitArray = JSON.parse(
        JSON.stringify(this.basePresetUnitArray)
      )
      this.presetValueKilo = undefined
      this.presetValueUnit = undefined
      this.product = JSON.parse(JSON.stringify(this.baseProduct))
    },
  },
}
</script>

<style scoped lang="scss">
.divider {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 70% minmax(30%, 200px);
  grid-gap: 20px;
}

@media screen and (max-width: 1000px) {
  .divider {
    position: relative;
    width: 100%;
    display: inline;
  }
}
</style>