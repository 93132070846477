<template>
  <div class="topBar">
    <div class="remove">
      <p>{{ title }}</p>
      <i
        v-if="displayDelete"
        @click="update('delete')"
        class="fas fa-trash-alt"
      ></i>
    </div>
    <div
      style="display: flex"
      v-if="isChanged || product.productName == 'New product'"
    >
      <MainButton
        :clickFunction="
          () => {
            update(action)
          }
        "
        :title="titleOne"
        styleClass="secondary"
        :buttonStyle="{
          'margin-left': '10px',
          padding: '7px 15px',
          width: '150px',
        }"
      />
      <MainButton
        :clickFunction="
          () => {
            save()
          }
        "
        :title="getTitleTwo"
        styleClass="primary"
        :buttonStyle="{
          'margin-left': '10px',
          padding: '7px 15px',
          width: '150px',
        }"
      />
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'

export default {
  data() {
    return {
      displayDelete: true,
      action: 'discard',
      titleOne: 'Discard',
      titleTwo: 'Update product',
    }
  },
  components: {
    MainButton,
  },
  props: ["updateProduct", "isChanged", "discardChanges", "saveChanges", "product", "title", "baseProduct"],
  computed: {
    getTitleTwo() {
      if (this.baseProduct.productName != "New product") {
        this.titleTwo = 'Update product',
          this.titleOne = 'Discard',
          this.action = 'discard',
          this.displayDelete = true
      }
      return this.titleTwo
    }
  },
  created() {
    if (this.product.productName == "New product") {
      this.titleOne = 'Cancel',
        this.titleTwo = 'Add product',
        this.action = 'forceDelete',
        this.displayDelete = false
    }
  },
  methods: {
    update(val) {
      this.updateProduct(val)
    },
    save() {
      this.saveChanges()
    },
  }
}
</script>

<style scoped lang="scss">
.remove {
  float: left;
  display: flex;
}

.topBar {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.remove i {
  transition: 0.2s ease-in-out;
  margin: auto 10px;
  cursor: pointer;
  font-size: 10pt;
}
.remove i:hover {
  font-size: 12pt;
}
.remove p {
  font-size: 15pt;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  position: relative;
  display: flex;
  margin: auto 0;
}
</style>