<template>
  <InfoWrapperBox>
    <div class="infoIcon">
      <H4>Webshop</H4>
      <i
        @mouseover="showApi = true"
        @mouseleave="showApi = false"
        class="fas fa-info-circle"
      ></i>
    </div>
    <div :style="displayApi" class="api">
      <InfoBox
        :data="{
          description:
            'Allows you to decide whether a product should be able to be fetched or not.',
        }"
      />
    </div>
    <div>
      <div>
        <label class="container">
          <H6>Show product in webshop</H6>
          <input
            v-model="product.webshop"
            type="radio"
            checked="checked"
            value="1"
            name="radio"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div>
        <label class="container">
          <H6>Don’t show product in webshop</H6>
          <input
            v-model="product.webshop"
            type="radio"
            value="0"
            name="radio"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </InfoWrapperBox>
</template>

<script>
import InfoBox from '@/components/InfoBox'
import H4 from '@/layout/typography/H4.vue'
import H6 from '@/layout/typography/H6.vue'
import InfoWrapperBox from '@/layout/InfoWrapperBox.vue'


export default {
  components: {
    InfoBox,
    H4,
    H6,
    InfoWrapperBox,
  },
  data() {
    return {
      showApi: false,
    }
  },
  computed: {
    displayApi() {
      if (this.showApi) {
        return { 'display': 'inline' }
      } else {
        return { 'display': 'none' }
      }
    }
  },
  props: ["product", "showInWebshop"]
}
</script>

<style scoped lang="scss">
.infoIcon {
  display: flex;
  margin: auto;
  position: relative;
}
.infoIcon i {
  margin: auto 0;
  margin-left: 5px;
}

.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 8pt;
  font-family: 'Roboto', sans-serif;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $accent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}
</style>