<template>
  <div class="presetSelectWrapper">
    <div>
      <H6>{{ title }}</H6>
      <div class="presetInput">
        <ion-icon name="add-outline"></ion-icon>
        <form @submit.prevent="addPreset(presetValue)">
          <StandardInput
            placeholder="Eg 0.1"
            type="number"
            v-model="presetValue"
            @blur.native="addPreset(presetValue)"
            @enter.native="addPreset(presetValue)"
            step="0.1"
          />
        </form>
      </div>
    </div>
    <div class="presetBoxWrapper">
      <div
        @click="removePreset(preset, presetArray)"
        class="presetBox"
        v-for="(preset, i) in presetList"
        :key="i"
      >
        <span>{{ preset }} st</span>
        <span class="removePreset">
          <i class="fas fa-times"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import StandardInput from '@/layout/StandardInput.vue'
import H6 from '@/layout/typography/H6.vue'

export default {
  components: {
    H6,
    StandardInput
  },
  data() {
    return {
      presetValue: 0,
    }
  },
  props: ['presetList', 'title', 'addPreset', 'removePreset', 'presetArray'],
}
</script>

<style>
.presetBoxWrapper {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 5px;
  border-radius: 5px;
  resize: none;
  flex-wrap: wrap;
}
.presetBox i {
  margin-left: 10px;
}
.presetBox:hover {
  box-shadow: rgba(0, 0, 0, 0.36) 0px 1px 4px;
}
.presetBox {
  transition: 0.1s ease-in-out;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 10px 10px 0 0;

  box-shadow: rgba(0, 0, 0, 0.26) 0px 1px 4px;
}
.presetInput {
  position: relative;
  width: 100%;
}
.presetInput ion-icon {
  position: absolute;
  font-size: 15pt;
  font-weight: bold;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.presetSelectWrapper {
  position: relative;
  width: calc(50% - 20px);
  padding: 5px;
}
</style>