<template>
  <InfoWrapperBox>
    <div style="margin-bottom: 10px">
      <H4>Pricing</H4>
      <H6>Fill in the price in kilos or per unit.</H6>
    </div>
    <div class="priceWrapper">
      <div class="inputSection">
        <H6>Price per kilo</H6>
        <div class="inputNumber">
          <span>SEK</span>
          <StandardInput v-model="product.kiloPrice" type="number" />
        </div>
        <div class="checkboxSection">
          <label class="container"
            >Show kilo in webshop
            <StandardInput
              type="checkbox"
              v-model="product.showKiloWebshop"
              :checked="product.showKiloWebshop == 1 ? 'checked' : ''"
              name="radio"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="inputSection">
        <H6>Price per unit</H6>
        <div class="inputNumber">
          <span>SEK</span>
          <StandardInput v-model="product.unitPrice" type="number" />
        </div>
        <div class="checkboxSection">
          <label class="container"
            >Show unit in webshop
            <input
              type="checkbox"
              v-model="product.showUnitWebshop"
              :checked="product.showUnitWebshop == 1 ? 'checked' : ''"
              name="radio"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
    <!-- <div class="equationWrapper">
      <H4>Price formula</H4>
      <H6>The price formula is a equation that will calculate a price for a product depending on the purchase price</H6>
      <div class="enableFormula">
        <H6>Enable formula price</H6>
        <md-switch></md-switch>
      </div>
      <div class="equation">
        <MathLiveInput :config="config" v-model="formula" v-on:input="input()">f(x)=</MathLiveInput>
      </div>      
    </div> -->
  </InfoWrapperBox>
</template>

<script>
import InfoWrapperBox from '@/layout/InfoWrapperBox'
import H6 from '../../../layout/typography/H6.vue'
import H4 from '../../../layout/typography/H4.vue'
import StandardInput from '../../../layout/StandardInput.vue'

import MathLive from 'mathlive'
import MathLiveInput from './../../../components/MathLiveInput.vue'

export default {
  components: {
    InfoWrapperBox,
    H6,
    H4,
    StandardInput,
    MathLive,
    MathLiveInput
  },
  props: ['product'],
  data() {
    return {
      formula: '7x',
      config: {
        smartMode: true,
        virtualKeyboardMode: 'manual'
      }
    }
  },
  mounted: function() {
    MathLive.renderMathInDocument()
  },
  methods: {
    input: function() {
      console.log(this.formula)
    }
  }
}
</script>

<style scoped lang="scss">
.enableFormula p {
  margin-right: 10px;
}
.enableFormula{
  display: flex;
  align-items: center;
}
.equationWrapper {
  width: 500px;
}
.equationWrapper .equation {
  border-radius: 5px;
  background: rgb(238, 238, 238);
  padding: 4px 15px;
}

.checkboxSection {
  width: 100%;
}

.checkboxSection input {
  width: 15px;
  height: 15px;
  border-radius: none;
}

.priceWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inputSection {
  position: relative;
  width: calc(50% - 20px);
  padding: 5px;
}

.inputNumber {
  width: 100%;
  position: relative;
}

.inputNumber span {
  position: absolute;
  right: 10px;
  font-size: 8pt;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  top: 50%;
  transform: translateY(-50%);
}

.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 8pt;
  font-family: 'Roboto', sans-serif;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $accent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7px;
  height: 7px;
  background: white;
  border-radius: 1px;
}
</style>
