<template>
  <div class="box">
    <GroupSelector :product="product" />
  </div>
</template>

<script>
import GroupSelector from '@/components/products/allProducts/GroupSelector'


export default {
  props: ["product"],
  components: {
    GroupSelector
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  position: relative;
  background: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
</style>