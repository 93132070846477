<template>
  <fragment>
    <H4>Groups</H4>
    <H6>Add to:</H6>
    <div class="groupsWrapper">
      <div
        @click="toggleSelectGroup(group)"
        class="groupItem"
        :key="group.id"
        v-for="group in allGroupsFiltered"
        :class="isSelected(group.id)"
      >
        {{ group.group_name }}
      </div>
    </div>
  </fragment>
</template>

<script>
import axios from "axios";
import H6 from '@/layout/typography/H6.vue';
import H4 from '@/layout/typography/H4.vue';

export default {
  components: {
    H6,
    H4,
  },
  props: ["product"],
  data() {
    return {
      allGroups: [],
      searchString: "",
    };
  },
  created() {
    this.getAllGroups();
  },
  computed: {
    allGroupsFiltered() {
      return this.allGroups.filter((group) => {
        if (
          group.group_name.includes(this.searchString) ||
          this.product.groups.find((el) => el.id === group.id)
        ) {
          return group;
        }
      });
    },
  },
  methods: {
    isSelected(groupId) {
      let index = this.product.groups.findIndex(
        (group) => group.id === groupId
      );
      if (index != -1) {
        return "selectedProduct";
      }
    },
    toggleSelectGroup(group) {
      let index = this.product.groups.findIndex((el) => el.id === group.id);

      if (index === -1) {
        this.product.groups.push({
          groupName: group.group_name,
          id: group.id,
        });
      } else {
        this.product.groups.splice(index, 1);
      }
    },
    getAllGroups() {
      axios
        .post("products/getAllGroups", {
          productId: this.product.id,
        })
        .then((res) => {
          this.allGroups = JSON.parse(JSON.stringify(res.data));
          this.sortAllGroups();
        });
    },
    sortAllGroups() {
      this.allGroups.sort((a, b) => {
        if (this.product.groups.find((product) => product.id === a.id)) {
          return -1;
        } else {
          return 1;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.groupsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

.groupItem {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background: #fbfbfb;
  text-align: left;
  -webkit-box-shadow: 0 0 10px -5px #b8b8b8;
  box-shadow: 0 0 10px -5px #b8b8b8;
  width: auto;
  display: block;
  cursor: pointer;
  border: 1px solid transparent;
  user-select: none;
}

.groupItem:hover {
  background: #f7f7f7;
}

.selectedProduct {
  border: 1px solid $accent;
}
</style>