<template>
  <InfoWrapperBox>
    <div>
      <H4>Title</H4>
      <StandardInput v-model="product.productName" />
    </div>
    <div>
      <H4>Description</H4>
      <StandardTextarea
        cols="30"
        rows="10"
        class="descriptionTextArea"
        placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...."
        v-model="product.description"
      />
    </div>
  </InfoWrapperBox>
</template>

<script>
import InfoWrapperBox from '@/layout/InfoWrapperBox'
import StandardInput from '@/layout/StandardInput.vue'
import StandardTextarea from '@/layout/StandardTextarea.vue'
import H4 from '@/layout/typography/H4.vue'

export default {
  components: {
    InfoWrapperBox,
    StandardInput,
    StandardTextarea,
    H4,
  },
  props: ["product"]
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  position: relative;
  background: white;
  border-radius: 5px;
  padding: 20px;
}
.box div:nth-child(1) {
  margin-bottom: 20px;
}
.box div p {
  margin: 5px 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}
</style>