<template>
  <div class="messageBox" :style="style.css">
    <div class="messageContent">
      <ion-icon :style="style.iconColor" :name="style.icon"></ion-icon>
      <p v-if="this.$store.state.messageBox != null">
        {{ this.$store.state.messageBox.message }}
      </p>
    </div>
    <div @click="closeMessageBox" class="closeMessage">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    style() {
      if (this.$store.state.messageBox == null) {
        return {
          css: {
            height: '0px',
            'border-top': '3px solid rgb(241, 241, 241)',
            transition: '0s ease-in-out',
          },
        }
      } else {
        let styleObject = {
          css: {
            'min-height': this.data.height,
            transition: String(this.data.transition) + 's ease-in-out',
          },
          icon: null,
        }

        this.checkProperties()

        if (this.$store.state.messageBox.type == 'success') {
          Object.assign(styleObject.css, {
            background: 'rgba(39, 121, 92, 0.2)',
            'border-top': '3px solid #27795C',
          })
          styleObject.icon = 'checkmark-outline'
          styleObject.iconColor = { background: 'rgb(39, 121, 92)'}
        } else if (this.$store.state.messageBox.type == 'error') {
          Object.assign(styleObject.css, {
            background: 'rgba(255, 0, 0, 0.2)',
            'border-top': '3px solid red',
          })
          styleObject.iconColor = { background: 'rgb(165 39 39)'}
          styleObject.icon = 'close-outline'
        } else {
          Object.assign(styleObject.css, {
            background: 'rgba(255, 255, 0, 0.2)',
            'border-top': '3px solid yellow',
          })
          styleObject.icon = 'fas fa-question'
        }
        return styleObject
      }
    },
  },
  methods: {
    closeMessageBox() {
      this.$store.commit('CLEAR_MESSAGEBOX')
    },
    checkProperties() {
      const _this = this

      // Check for auto close
      if (this.$store.state.messageBox != null && this.data.close) {
        if (this.data.close.auto == true) {
          setTimeout(function () {
            _this.closeMessageBox()
          }, this.data.close.time * 1000)
        }
      }
    },
  },
}
</script>

<style scoped>
.messageBox {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  width: calc(100% + 20px);
  margin: 10px 0;
  position: relative;
}
.messageContent,
.closeMessage {
  display: flex;
  position: relative;
  margin: auto 0;
}
.messageContent ion-icon:nth-child(1) {
  margin: auto 25px auto 50px;
  height: 25px;
  width: 25px;
  padding: 7px;
  display: flex;
  font-size: 13pt;
  border-radius: 50%;
  color: white;
}

.messageContent p {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8vw;
  font-weight: bold;
  display: flex;
  line-height: 50px;
}

.closeMessage {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 40px;
}

.closeMessage div:nth-child(1) {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translateY(calc(-50% + 1px));
  background: rgb(112, 112, 112);
}
.closeMessage div:nth-child(2) {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg) translateY(calc(-50% + 1px));
  background: rgb(112, 112, 112);
}

@media screen and (max-width: 1000px) {
  .messageBox {
    width: calc(100%);
  }
}
</style>