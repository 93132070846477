<template>
  <InfoWrapperBox>
    <div>
      <H4>Amount</H4>
      <H6>Choose how many kilos or units a customer can buy from your site.</H6>
    </div>
    <div class="preset">
      <Preset
        :removePreset="removePreset"
        :presetArray="presetKiloArray"
        :addPreset="addKiloPreset"
        :presetList="presetKiloArraySorted"
        :title="'Kilo'"
      />
      <Preset
        :removePreset="removePreset"
        :presetArray="presetUnitArray"
        :addPreset="addUnitPreset"
        :presetList="presetUnitArraySorted"
        :title="'Units'"
      />
    </div>
  </InfoWrapperBox>
</template>

<script>
import Preset from '@/components/products/viewProduct/Preset/Preset'
import InfoWrapperBox from '@/layout/InfoWrapperBox.vue'
import H4 from '../../../layout/typography/H4.vue'
import H6 from '../../../layout/typography/H6.vue'

export default {
  components: {
    Preset,
    InfoWrapperBox,
    H4,
    H6
  },
  props: ['presetKiloArraySorted', 'presetUnitArraySorted', 'addUnitPreset', 'addKiloPreset', 'removePreset', 'presetUnitArray', 'presetKiloArray'],
}
</script>

<style scoped lang="scss">
.preset {
  display: flex;
  justify-content: space-between;
}
</style>